.scrollmagic-pin-spacer {
    z-index: 99999;
}

.greet {
    position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @include vw($min-height: 900);
    // @include vw($height: 1080);
    z-index: 10;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    ;
    justify-content: space-between;

    @include respond-to(xs) {
        min-height: 0;
        // min-height: 812px;
    }

    &__header {
        position: relative;
        z-index: 4;

        .site-width {
            justify-content: space-between;
            align-items: center;
            display: flex;
            @include vw($padding-top: 15,
                $padding-bottom: 15, $border-bottom: 1 solid);

            @include respond-to(xs) {
                padding: 10px 15px;
                display: flex !important;
            }
        }
    }

    &__text {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        z-index: 4;
        @include vw($font-size: 48,
            $margin-bottom: 180);
        font-family: $ff;
        text-align: center;

        p {
            @include vw($padding-top: 390);

            @include respond-to(xs) {
                padding-top: 30vw;
            }
        }

        @include respond-to(xs) {
            font-size: 18px;
            margin-bottom: 41vh;
        }
    }

    .scroll {
        // position: relative;
        z-index: 4;
        @include vw($bottom: 25);

        @include respond-to(xs) {
            bottom: 17px;
        }

        &__img {
            @include vw($box-shadow: inset 0 0 0 4 #4e9cad);

            @include respond-to(xs) {
                box-shadow: inset 0 0 0 2px $brand;
            }

        }

        &__circle {
            &:before {

                content: '';
                background: $brand;
            }
        }

        &__text {
            @include vw($margin-top: 25);
        }
    }

    &__logo {
        @include vw($width: 60);

        @include respond-to(xs) {
            width: 40px;
        }
    }

    &__bg {
        width: 100%;
        height: 100%;
        // background:  url(/images/mask.png) center center no-repeat;
        background: url(/images/greet__bg.jpg) center center no-repeat;
        // background:  #fff;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include vw($border-radius: 5);
    }

    &__mask {
        z-index: 2;
        position: absolute;
        top: 0;
        left: auto;
        width: 100%;
        height: 100%;
        overflow: hidden;
        // @include vw($border: 100 solid #fff);

        img {
            max-width: none;
            // width: 1920px;
            top: 50%;
            left: 50%;
            height: auto;
            min-height: 100%;
            transform: translate(-50%, -50%);
            position: absolute;
            @include vw($width: 1920);

            @include respond-to(xs) {
                display: none;

            }

            &.visible-mobile {
                display: none;

                @include respond-to(xs) {
                    display: block;
                    width: 102vw;
                }
            }

        }
    }

    &__title {
        @include vw($font-size: 110);
        font-weight: $ext-bold;
        text-align: center;
        position: relative;
        z-index: 99;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: url(/images/greet__bg.jpg);
        background-size: 100vw 100vh;
        background-repeat: no-repeat;

        span {
            font-weight: inherit;
        }
    }

}

.hero {
    overflow: hidden;
    max-width: 100%;
    height: 100vh;
    @include vw($min-height: 900);
    position: relative;
    background: url(/images/hero-coner.svg) left top no-repeat, url(/images/bg-hero.jpg) center center no-repeat;
    background-size: 12%, cover;
    top: 0 !important;

    @include respond-to(xs) {

        min-height: 0px;
    }

    &__bg {
        height: 100%;
        width: 100vw;
        position: absolute;
        z-index: 0;

        @include respond-to(xs) {
            // display: none;
        }

        &.visible-mobile {
            display: none;

            @include respond-to(xs) {
                display: block;

            }
        }

        &-img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 1;
            position: relative;

        }

        svg {
            z-index: 1;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            min-width: 100% !important;
            min-height: 100%;
            width: auto;
            height: auto;
            max-height: 110%;
        }

        &-img-2 {
            object-fit: cover;
            z-index: 2;
            position: absolute;
            bottom: 0;
            /* height: 100%; */
            width: 100%;
            bottom: -10px;
            right: 0px;
        }

        &-doctor {
            position: absolute;
            @include vw($top: 506,
                // $bottom: 42,
                $left: 918, );
            // $width: 248);
            // width: 13%;
            // z-index: 0;
            // transform: scale(150) translate(-50%, -50%);
            // width: 2520%;
            max-width: none;
            // z-index: 1;
            width: 13%;
            // transform: translate(-15%, -12.6%);

            @include respond-to(xs) {
                top: 49vh;
                z-index: 1;
                left: 19vh;
                width: 37%;
                // width: 5747%;
                // transform: translate(-18%, -12.5%);
            }
        }

    }

    &__shape {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        transform: translateY(47%);
        @include vw($width: 412);

        img {
            width: 100%;
        }

        @include respond-to(xs) {
            width: 29%;

        }
    }

    .site-width {
        display: flex;
        height: 100%;
        z-index: 2;
        position: relative;

        @include respond-to(xs) {
            display: block;
        }

    }

    &__content {
        display: flex;
        // align-items: center;
        flex-basis: 53%;
        flex-grow: 0;
        // @include vw($margin-top: -60);

        @include respond-to(xs) {
            display: block;
            padding-top: 184px;
            padding-top: 18vh;
            height: 100%;
            margin-top: 0;
        }
    }

    &__img {
        @include vw($width: 180,
            $margin-right: 96);

        @include respond-to(xs) {
            width: 30px;
            margin: 0px auto;
            padding-top: 30px;
        }

        img {
            width: 100%;
        }
    }

    &__text {
        @include vw($padding-left: 67);
        position: relative;

        @include respond-to(xs) {
            padding-left: 20px;
            padding-right: 0;
            margin-top: 117px;
            margin-top: 0;
            text-align: left;
            font-size: 34px;
            z-index: 2;
        }
    }

    .title {
        // margin-top: 300px;
        position: relative;
        @include vw($margin-top: 300);
        opacity: 0;
        transform: translateY(-10%);
        ;

        @include respond-to(xs) {
            z-index: 2 !important;
            // font-size: 34px;
            // margin-left: -15px;
            // margin-right: -15px;
            margin-top: 0px;
            font-size: 34px;
            margin-left: 0;
            margin-right: 0;
            margin: 0;
        }

        span {
            font-weight: inherit;

            @include respond-to(xs) {
                display: block;
                font-family: $ff;
                font-weight: $ext-bold;
                line-height: 1.1;

            }
        }
    }

    .text--sm {
        opacity: 0;
        transform: translateY(-10%);
        ;

        @include respond-to(xs) {
            width: 60%;
            margin: 60px auto;
            margin: 10px 0;
            z-index: 3 !important;
            ;
        }
    }

    &__scroll {
        z-index: 3;
    }

}