
$brand: #69CBE2; 
$sec: #F2EEE4;  
$dark: #456E79;
$brown: #F2EEE4; 
$text: #000;
/*
==============================
Fonts
==============================
*/

$ff:   'Poppins', sans-serif; 
$reg: 400;
$med: 500;
$semi-bold: 600;
$bold: 700;
$ext-bold: 800;
